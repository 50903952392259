export default {
  namespaced: true,
  state: {
    user: {
      name: null,
      email: null,
      profile: null
    },
    token: null
  },
  getters: {
    user: (state) => state.user,
    token: (state) => state.token
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    UPDATE_PREFERENCE(state, payload) {
      let user = state.user;
      user.preference = payload;
      Object.assign(state.user, user);
    }
  },
  actions: {
    ActionSetUser({ commit }, payload) {
      commit('SET_USER', payload);
    },
    ActionSetToken({ commit }, payload) {
      commit('SET_TOKEN', payload);
    },
    ActionUpdatePreference({ commit }, payload) {
      commit('UPDATE_PREFERENCE', payload);
    }
  }
};
