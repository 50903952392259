<template>
  <router-view />
</template>

<script>
import { colors } from 'quasar';
export default {
  name: 'App',
  async mounted() {
    this.$q.addressbarColor.set(colors.getBrand('primary'));
    console.log(this.$q.version);
    this.dialogAdBlock();
  }
};
</script>
