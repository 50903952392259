export default {
  namespaced: true,
  state: {
    creative: [],
    channelId: null,
    creativeAll: [],
    creativeSelect: null,
    filterCreative: null,
    dialogCreative: false,
    formCreativeData: {},
    creativeSelections: {},
    formCreativeImages: []
  },
  getters: {
    creative: (state) => state.creative,
    channelId: (state) => state.channelId,
    creativeAll: (state) => state.creativeAll,
    creativeSelect: (state) => state.creativeSelect,
    filterCreative: (state) => state.filterCreative,
    dialogCreative: (state) => state.dialogCreative,
    formCreativeData: (state) => state.formCreativeData
  },
  mutations: {
    SET_CREATIVE_ALL(state, payload) {
      state.creativeAll = payload;
      state.creative = payload;
    },
    SET_CREATIVE(state, payload) {
      state.creative = payload;
    },
    SET_CHANNEL_ID(state, payload) {
      state.channelId = payload;
    },
    SET_CREATIVE_SELECT(state, payload) {
      state.creativeSelect = payload;
    },
    SET_FILTER_CREATIVE(state, payload) {
      state.filterCreative = payload;
    },
    SET_DIALOG_CREATIVE(state, payload) {
      if (payload.value) {
        state.creativeSelect = payload.value;
        state.dialogCreative = true;
      } else {
        state.dialogCreative = payload;
        state.creativeSelect = null;
      }
    },
    SET_FORM_CREATIVE_DATA(state, payload) {
      state.formCreativeData = payload;
    },

    SET_CREATIVE_SELECTIONS(state, payload) {
      state.creativeSelections = payload;
    },

    PUSH_NEW_IMAGE_TO_FORM_CREATIVE_IMAGES(state, payload) {
      var index;
      if ((index = state.formCreativeImages.findIndex((creativeImage) => creativeImage.id == payload.id)) != -1) {
        state.formCreativeImages[index] = payload;
      } else {
        state.formCreativeImages.push(payload);
      }
    },

    SET_FORM_CREATIVE_IMAGES(state, payload) {
      state.formCreativeImages = payload;
    }
  },
  actions: {
    ActionSetCreativeAll({ commit }, payload) {
      commit('SET_CREATIVE', payload);
    },
    ActionSetCreative({ commit }, payload) {
      commit('SET_CREATIVE', payload);
    },
    ActionSetChannelId({ commit }, payload) {
      commit('SET_CHANNEL_ID', payload);
    },
    ActionSetCreativeSelect({ commit }, payload) {
      commit('SET_CREATIVE_SELECT', payload);
    },
    ActionSetFilterCreative({ commit }, payload) {
      commit('SET_FILTER_CREATIVE', payload);
    },
    ActionSetDialogCreative({ commit }, payload) {
      commit('SET_DIALOG_CREATIVE', payload);
    },
    ActionSetFormCreativeData({ commit }, payload) {
      commit('SET_FORM_CREATIVE_DATA', payload);
    },
    ActionSetCreativeSelections({ commit }, payload) {
      commit('SET_CREATIVE_SELECTIONS', payload);
    },
    ActionPushNewImageToCreativeImages({ commit }, payload) {
      commit('PUSH_NEW_IMAGE_TO_FORM_CREATIVE_IMAGES', payload);
    },
    ActionSetFormCreativeImages({ commit }, payload) {
      commit('SET_FORM_CREATIVE_IMAGES', payload);
    }
  }
};
