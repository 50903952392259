import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './quasar';
import i18n from './plugins/i18n';
import axios from './plugins/axios';
import moment from './plugins/moment';
import lodash from './plugins/lodash';
import money from 'v-money';

import Global from '@m/GlobalMixin';
Vue.mixin(Global);

Vue.config.productionTip = false;

console.log('modo: ', process.env.NODE_ENV);
if (process.env.NODE_ENV === 'production') console.log = function () {};

Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  suffix: '',
  precision: 2,
  masked: false
});

new Vue({
  axios,
  moment,
  lodash,
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
