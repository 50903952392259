export default {
  namespaced: true,
  state: {
    users: [],
    usersAll: [],
    userSelect: null,
    filterUser: null,
    dialogUser: false
  },
  getters: {
    users: (state) => state.users,
    usersAll: (state) => state.usersAll,
    userSelect: (state) => state.userSelect,
    filterUser: (state) => state.filterUser,
    dialogUser: (state) => state.dialogUser
  },
  mutations: {
    SET_USERS_ALL(state, payload) {
      state.usersAll = payload;
      state.users = payload;
    },
    SET_USERS(state, payload) {
      state.users = payload;
    },
    SET_USER_SELECT(state, payload) {
      state.userSelect = payload;
    },
    SET_FILTER_USER(state, payload) {
      state.filterUser = payload;
    },
    UPDATE_USER(state, payload) {
      let user = this.users.find((item) => item.id == payload.id);
      Object.assign(user, state.users);
    },
    SET_DIALOG_USER(state, payload) {
      if (payload.value) {
        state.userSelect = payload.value;
        state.dialogUser = true;
      } else {
        state.dialogUser = payload;
        state.userSelect = null;
      }
    }
  },
  actions: {
    ActionSetUsersAll({ commit }, payload) {
      commit('SET_USERS_ALL', payload);
    },
    ActionSetUsers({ commit }, payload) {
      commit('SET_USERS', payload);
    },
    ActionSetUserSelect({ commit }, payload) {
      commit('SET_USER_SELECT', payload);
    },
    ActionSetFilterUser({ commit }, payload) {
      commit('SET_FILTER_USER', payload);
    },
    ActionSetDialogUser({ commit }, payload) {
      commit('SET_DIALOG_USER', payload);
    },
    ActionUpdateUser({ commit }, payload) {
      commit('UPDATE_USER', payload);
    }
  }
};
