import Vue from 'vue';
import lodash from 'lodash';

const install = (Vue) => {
  Vue.prototype.$_ = lodash;
};

Vue.use({ install });

export default { install };
