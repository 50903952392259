import Vue from 'vue';

import './styles/quasar.scss';
import lang from 'quasar/lang/pt-br.js';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/ionicons-v4/ionicons-v4.css';
import '@quasar/extras/mdi-v6/mdi-v6.css';
import '@quasar/extras/eva-icons/eva-icons.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import Quasar from 'quasar/src/vue-plugin.js';import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import AddressbarColor from 'quasar/src/plugins/AddressbarColor.js';;

Vue.use(Quasar, {
  config: {},
  plugins: {
    Loading,
    Notify,
    Dialog,
    AddressbarColor
  },
  lang: lang
});
