import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import router from '@/router';

const install = (Vue) => {
  Vue.prototype.$http = axios.create({
    baseURL: process.env.VUE_APP_SERVER
  });
  //interceptar requisições
  Vue.prototype.$http.interceptors.request.use(
    (config) => {
      const token = localStorage['afilio_store'] ? localStorage.getItem('afilio_store') : null;
      if (token) config.headers['Authorization'] = `Bearer ${token}`;
      return config;
    },
    (error) => Promise.reject(error)
  );
  Vue.prototype.$http.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error && error.message == 'Request failed with status code 403' && router.currentRoute.name != 'MyAccount') {
        store.dispatch('User/ActionSetUsers', null);
        router.push({
          name: 'Auth'
        });
        // window.location.href = `${process.env.VUE_ORIGIN}/auth`;
      }
      return Promise.reject(error);
    }
  );
};

Vue.use({ install });

export default { install };
