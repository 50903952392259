export default {
  namespaced: true,
  state: {
    campaigns: [],
    campaignsAll: [],
    campaignsSummary: [],
    manageCampaignsVisibleColumns: [],
    myCampaigns: [],
    channels: [],
    channelsAll: [],
    channelsStatusCount: {
      pending: null,
      approved: null,
      paused: null,
      reproved: null,
      deleted: null
    },
    campaignSelect: null,
    filterCampaign: null,
    filterChannels: null,
    dialogCampaign: false,
    campaignValidationHistory: null,
  },
  getters: {
    campaigns: (state) => state.campaigns,
    manageCampaignsVisibleColumns: (state) => state.manageCampaignsVisibleColumns,
    myCampaigns: (state) => state.myCampaigns,
    campaignsAll: (state) => state.campaignsAll,
    channels: (state) => state.channels,
    channelsAll: (state) => state.channelsAll,
    channelsStatusCount: (state) => state.channelsStatusCount,
    campaignSelect: (state) => state.campaignSelect,
    filterCampaign: (state) => state.filterCampaign,
    filterChannels: (state) => state.filterChannels,
    dialogCampaign: (state) => state.dialogCampaign,
    campaignsSummary: (state) => state.campaignsSummary,
    campaignValidationHistory: (state) => state.campaignValidationHistory
  },
  mutations: {
    SET_CAMPAIGNS_ALL(state, payload) {
      state.campaignAll = payload;
      state.campaigns = payload;
    },
    SET_MANAGE_CAMPAIGNS_VISIBLE_COLUMNS(state,payload) {
      state.manageCampaignsVisibleColumns = payload;
    },
    SET_MY_CAMPAIGNS(state, payload) {
      state.myCampaigns = payload;
    },
    SET_CHANNELS_ALL(state, payload) {
      state.channelsAll = payload;
      state.channels = payload;

      state.channelsStatusCount.pending = payload.filter((item) => item.campaign_site_status == 0).length;
      state.channelsStatusCount.approved = payload.filter((item) => item.campaign_site_status == 1).length;
      state.channelsStatusCount.paused = payload.filter((item) => item.campaign_site_status == 2).length;
      state.channelsStatusCount.reproved = payload.filter((item) => item.campaign_site_status == 3).length;
      state.channelsStatusCount.deleted = payload.filter((item) => item.campaign_site_status == 4).length;
    },
    SET_CAMPAIGNS(state, payload) {
      state.campaigns = payload;
    },
    SET_CHANNEL(state, payload) {
      state.channels = payload;
    },
    SET_CAMPAIGN_SELECT(state, payload) {
      state.campaignSelect = payload;
    },
    SET_FILTER_CAMPAIGN(state, payload) {
      state.filterCampaign = payload;
    },
    SET_FILTER_CHANNELS(state, payload) {
      state.filterChannels = payload;
    },
    SET_DIALOG_CAMPAIGN(state, payload) {
      if (payload.value) {
        state.campaignSelect = payload.value;
        state.dialogCampaign = true;
      } else {
        state.dialogCampaign = payload;
        state.campaignSelect = null;
      }
    },
    SET_CAMPAIGNS_SUMMARY(state, payload) {
      state.campaignsSummary = payload;
    },
    SET_CAMPAIGN_VALIDATION_HISTORY(state, payload) {
      state.campaignValidationHistory = payload;
    }
  },
  actions: {
    ActionSetCampaignsAll({ commit }, payload) {
      commit('SET_CAMPAIGNS_ALL', payload);
    },
    ActionSetMyCampaigns({ commit }, payload) {
      commit('SET_MY_CAMPAIGNS', payload);
    },
    ActionSetChannelsAll({ commit }, payload) {
      commit('SET_CHANNELS_ALL', payload);
    },
    ActionSetCampaigns({ commit }, payload) {
      commit('SET_CAMPAIGN', payload);
    },
    ActionSetChannels({ commit }, payload) {
      commit('SET_CHANNEL', payload);
    },
    ActionSetCampaignSelect({ commit }, payload) {
      commit('SET_CAMPAIGN_SELECT', payload);
    },
    ActionSetFilterCampaign({ commit }, payload) {
      commit('SET_FILTER_CAMPAIGN', payload);
    },
    ActionSetDialogCampaign({ commit }, payload) {
      commit('SET_DIALOG_CAMPAIGN', payload);
    },
    ActionSetCampaignsSummary({ commit }, payload) {
      commit('SET_CAMPAIGNS_SUMMARY', payload);
    },
    ActionSetCampaignValidationHistory({ commit }, payload) {
      commit('SET_CAMPAIGN_VALIDATION_HISTORY', payload);
    },
    ActionSetManageCampaignsVisibleColumns({ commit }, payload) {
      commit('SET_MANAGE_CAMPAIGNS_VISIBLE_COLUMNS', payload);
    }
  }
};
