export default {
  namespaced: true,
  state: {
    advertisers: [],
    advertisersAll: [],
    advertiserSelect: null,
    filterAdvertiser: null,
    dialogAdvertiser: false,
    approvedCampaigns: []
  },
  getters: {
    advertisers: (state) => state.advertisers,
    advertisersAll: (state) => state.advertisersAll,
    advertiserSelect: (state) => state.advertiserSelect,
    filterAdvertiser: (state) => state.filterAdvertiser,
    dialogAdvertiser: (state) => state.dialogAdvertiser,
    approvedCampaigns: (state) => state.approvedCampaigns
  },
  mutations: {
    SET_ADVERTISERS_ALL(state, payload) {
      state.advertisersAll = payload;
      state.advertisers = payload;
    },
    SET_ADVERTISERS(state, payload) {
      state.advertisers = payload;
    },
    SET_ADVERTISER_SELECT(state, payload) {
      state.advertiserSelect = payload;
    },
    SET_FILTER_ADVERTISER(state, payload) {
      state.filterAdvertiser = payload;
    },
    SET_APPROVED_CAMPAIGNS(state, payload) {
      state.approvedCampaigns = payload;
    },
    SET_DIALOG_ADVERTISER(state, payload) {
      if (payload.value) {
        state.advertiserSelect = payload.value;
        state.dialogAdvertiser = true;
      } else {
        state.dialogAdvertiser = payload;
        state.advertiserSelect = null;
      }
    }
  },
  actions: {
    ActionSetAdvertisersAll({ commit }, payload) {
      commit('SET_ADVERTISERS_ALL', payload);
    },
    ActionSetAdvertisers({ commit }, payload) {
      commit('SET_ADVERTISERS', payload);
    },
    ActionSetAdvertiserSelect({ commit }, payload) {
      commit('SET_ADVERTISER_SELECT', payload);
    },
    ActionSetFilterAdvertiser({ commit }, payload) {
      commit('SET_FILTER_ADVERTISER', payload);
    },
    ActionSetDialogAdvertiser({ commit }, payload) {
      commit('SET_DIALOG_ADVERTISER', payload);
    },
    ActionSetApprovedCampaigns({ commit }, payload) {
      commit('SET_APPROVED_CAMPAIGNS', payload);
    }
  }
};
