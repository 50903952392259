export default {
  namespaced: true,
  state: {
    reports: [],
    reportsTotal: [],
    filterReports: null
  },
  getters: {
    reports: (state) => state.reports,
    filterReports: (state) => state.filterReports,
    reportsTotal: (state) => state.reportsTotal
  },
  mutations: {
    SET_REPORTS(state, payload) {
      state.reports = payload;
    },
    SET_FILTER_REPORTS(state, payload) {
      state.filterReports = payload;
    },
    SET_REPORTS_TOTAL(state, payload) {
      state.reportsTotal = payload;
    }
  },
  actions: {
    ActionSetReports({ commit }, payload) {
      commit('SET_REPORTS', payload);
    },
    ActionSetFilterReports({ commit }, payload) {
      commit('SET_FILTER_REPORTS', payload);
    },
    ActionSetReportsTotal({ commit }, payload) {
      commit('SET_REPORTS_TOTAL', payload);
    }
  }
};
