export default {
  namespaced: true,
  state: {
    affiliates: [],
    affiliateSelect: null,
    filterAffiliate: null,
    dialogAffiliate: false,
    affiliateBonus: [],
    filterAffiliateBonus: null
  },
  getters: {
    affiliates: (state) => state.affiliates,
    affiliateSelect: (state) => state.affiliateSelect,
    filterAffiliate: (state) => state.filterAffiliate,
    dialogAffiliate: (state) => state.dialogAffiliate,
    affiliateBonus: (state) => state.affiliateBonus,
    filterAffiliateBonus: (state) => state.filterAffiliateBonus
  },
  mutations: {
    SET_AFFILIATES(state, payload) {
      state.affiliates = payload;
    },
    SET_AFFILIATE_SELECT(state, payload) {
      state.affiliateSelect = payload;
    },
    SET_FILTER_AFFILIATE(state, payload) {
      state.filterAffiliate = payload;
    },
    SET_DIALOG_AFFILIATE(state, payload) {
      if (payload.value) {
        state.affiliateSelect = payload.value;
        state.dialogAffiliate = true;
      } else {
        state.dialogAffiliate = payload;
        state.affiliateSelect = null;
      }
    },
    SET_AFFILIATE_BONUS(state, payload) {
      state.affiliateBonus = payload;
    },
    SET_FILTER_AFFILIATE_BONUS(state, payload) {
      state.filterAffiliateBonus = payload;
    }
  },
  actions: {
    ActionSetAffiliates({ commit }, payload) {
      commit('SET_AFFILIATES', payload);
    },
    ActionSetAffiliateSelect({ commit }, payload) {
      commit('SET_AFFILIATE_SELECT', payload);
    },
    ActionSetFilterAffiliate({ commit }, payload) {
      commit('SET_FILTER_AFFILIATE', payload);
    },
    ActionSetDialogAffiliate({ commit }, payload) {
      commit('SET_DIALOG_AFFILIATE', payload);
    },
    ActionSetAffiliateBonus({ commit }, payload) {
      commit('SET_AFFILIATE_BONUS', payload);
    },
    ActionSetFilterAffiliateBonus({ commit }, payload) {
      commit('SET_FILTER_AFFILIATE_BONUS', payload);
    }
  }
};
