export default {
  namespaced: true,
  state: {
    dialogCommissionSchedule: false,
    dialogCreateCommissionSchedule: false,
    commissionScheduleSelect: null,
    commissionScheduleCreationData: null,
    commissionSchedulesList: [],
    channelList: [],
    groupsList: []
  },
  getters: {
    dialogCommissionSchedule: (state) => state.dialogCommissionSchedule,
    dialogCreateCommissionSchedule: (state) => state.dialogCreateCommissionSchedule,
    commissionScheduleSelect: (state) => state.commissionScheduleSelect,
    commissionScheduleCreationData: (state) => state.commissionScheduleCreationData,
    commissionSchedulesList: (state) => state.commissionSchedulesList,
    groupsList: (state) => state.groupsList,
    channelList: (state) => state.channelList
  },
  mutations: {
    SET_DIALOG_COMMISSION_SCHEDULE(state, payload) {
      if (payload.value) {
        state.commissionScheduleSelect = payload.value;
        state.dialogCommissionSchedule = true;
      } else {
        state.dialogCommissionSchedule = payload;
        state.commissionScheduleSelect = null;
      }
    },

    SET_DIALOG_CREATE_COMMISSION_SCHEDULE(state, payload) {
      if (payload.value) {
        state.dialogCreateCommissionSchedule = true;
        state.commissionScheduleCreationData = payload.value;
      } else {
        state.dialogCreateCommissionSchedule = payload;
        state.commissionScheduleCreationData = null;
      }
    },

    SET_COMMISSION_SCHEDULES_LIST(state, payload) {
      state.commissionSchedulesList = payload;
    },

    SET_CHANNELS_LIST(state, payload) {
      state.channelList = payload;
    },

    SET_COMMISSION_GROUPS_LIST(state, payload) {
      state.groupsList = payload;
    }
  },
  actions: {
    ActionSetDialogCommissionSchedule({ commit }, payload) {
      commit('SET_DIALOG_COMMISSION_SCHEDULE', payload);
    },

    ActionSetDialogCreateCommissionSchedule({ commit }, payload) {
      commit('SET_DIALOG_CREATE_COMMISSION_SCHEDULE', payload);
    },

    ActionSetCommissionSchedulesList({ commit }, payload) {
      commit('SET_COMMISSION_SCHEDULES_LIST', payload);
    },

    ActionSetChannelsList({ commit }, payload) {
      commit('SET_CHANNELS_LIST', payload);
    },

    ActionSetCommissionGroupsList({ commit }, payload) {
      commit('SET_COMMISSION_GROUPS_LIST', payload);
    }
  }
};
