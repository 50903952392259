export default {
  namespaced: true,
  state: {
    channels: [],
    channelSelect: null,
    filterChannel: null,
    dialogChannel: false
  },
  getters: {
    channels: (state) => state.channels,
    channelSelect: (state) => state.channelSelect,
    filterChannel: (state) => state.filterChannel,
    dialogChannel: (state) => state.dialogChannel
  },
  mutations: {
    SET_CHANNELS(state, payload) {
      state.channels = payload;
    },
    SET_CHANNEL_SELECT(state, payload) {
      state.channelSelect = payload;
    },
    SET_FILTER_CHANNEL(state, payload) {
      state.filterChannel = payload;
    },
    SET_DIALOG_CHANNEL(state, payload) {
      if (payload.value) {
        state.channelSelect = payload.value;
        state.dialogChannel = true;
      } else {
        state.dialogChannel = payload;
        state.channelSelect = null;
      }
    }
  },
  actions: {
    ActionSetChannels({ commit }, payload) {
      commit('SET_CHANNELS', payload);
    },
    ActionSetChannelSelect({ commit }, payload) {
      commit('SET_CHANNEL_SELECT', payload);
    },
    ActionSetFilterChannel({ commit }, payload) {
      commit('SET_FILTER_CHANNEL', payload);
    },
    ActionSetDialogChannel({ commit }, payload) {
      commit('SET_DIALOG_CHANNEL', payload);
    }
  }
};
